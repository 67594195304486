App = {};
System = {};

if (typeof _ !== 'undefined') {
    _.templateSettings = {
        interpolate: /\{\{(.+?)\}\}/g,
        evaluate: /\{%(.+?)%\}/g
    };
}


System.loadingShow = function () {
    $('.loading').show();
};
System.loadingHide = function () {
    $('.loading').hide();
};
System.showErrors = function (text, type, time) {
    var type = type || 'error';
    var time = time || null;
    show_alert(text, type, time);
};

System.error = System.showErrors;

System.notify = function (text, type, time) {
    var type = type || 'success';
    var time = time || null;
    show_alert(text, type, time);
};

System.info = function (text, type, time) {
    var type = type || 'info';
    var time = time || null;
    show_alert(text, type, time);
};

System.warning = function (text, type, time) {
    var type = type || 'error';
    var time = time || null;
    show_alert(text, type, time);
};

System.hideErrors = function () {
    fade_alert();
};

System.getQueryParam = function (variable, defaultValue) {
    defaultValue = defaultValue || null;
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return defaultValue;
};
(function ($) {
    $.fn.asyncBackgroundLoader = function (options, callback) {
        var defaults = {
            loader: '.layer-loader',
            timeout: 20000
        };

        var options = $.extend(defaults, options);
        $(this).css({ opacity: 0 });
        var count = $(this).length;
        var self = $(this);

        return $(this).each(function () {
            var $this = $(this);
            $(options.loader).show();

            $bgi = $('<img>')
            $bgi.attr('src', function () {
                var imgUrl = $this.css('backgroundImage');
                imgUrl = imgUrl.replace(/^url\(['"]?([a-zA-Z0-9\.\\\/:_-]+)['"]?\)$/, "$1");
                return imgUrl;
            });
            $('body').append($bgi)

            $bgi.load(function () {
                count--;
                $(this).remove()

                if (count <= 1) {
                    $(options.loader).fadeOut();
                    self.animate({ opacity: 1 });
                    if (typeof callback == 'function') {
                        callback.call();
                    }
                }
            })
            setTimeout(function () {
                $(options.loader).fadeOut();
                self.animate({ opacity: 1 });
            }, options.timeout);
        })
    }

    var options = {
        message: 'We use cookies to improve user experience, and analyze website traffic. For these reasons, we may share your site usage data with our analytics partners. By clicking “Accept Cookies,” you consent to store on your device all the technologies described in our Cookie Policy.',
        expires: 30,
        cookieTypes: []
    }
    $('body').ihavecookies(options);
})(jQuery);



var CnModal = function (div, ok, close, params) {
    var container = div;
    var options = params;
    var callbacks = {
        'success': ok || null,
        'close': close || null
    };
    var me = this;
    me.getCallbacks = function () {
        return callbacks;
    };
    $(container).find('.close-btn').on('click', function (e) {
        e.preventDefault();
        me.hide();
        if (typeof callbacks.close == 'function') {
            callbacks.close.call(me);
        }
    });

    $(container).find('.ok-btn').on('click', function (e) {
        e.preventDefault();
        me.hide();
        if (typeof callbacks.success == 'function') {
            callbacks.success.call(me);
        }
    });
    this.show = function (css) {
        var me = this;
        $('.overlay').show();
        if (css) {
            $(container).css(css);
        }
        var close = $(container).find('.close');

        if (!close.hasClass('handled')) {
            close.off('click');
            close.on('click', function (e) {
                //                alert(1)
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                var callbacks = me.getCallbacks();
                me.hide();
                if (typeof callbacks.close == 'function') {
                    callbacks.close.call(me);
                }
            })
            $(container).find('input:text, input:password').focus(function () {
                if ($(this).prev('.input-group-addon').length > 0) {
                    $(this).prev('.input-group-addon').addClass('hover');
                }
            })
            $('input:text, input:password', container).blur(function () {
                if ($(this).prev('.input-group-addon').length > 0) {
                    $(this).prev('.input-group-addon').removeClass('hover');
                }
            })

            close.addClass('handled');
        }
        $(container).fadeIn();
        if ($(container).find('input:text').length > 0) {
            $(container).find('input:text').first().focus();
        } else {
            // $(container).find('input:password').first().focus();
        }
    };
    this.hide = function () {
        $(container).fadeOut();
        $('.overlay').hide();
    }
};

var RedirectHelper = (function (parent) {
    var RedirectHelper = function (parent) {
    };

    var storage_key = 'redirect_helper_key';

    RedirectHelper.setAction = function (callback) {
        window.localStorage.setItem(storage_key, callback);
    };

    RedirectHelper.doAction = function () {
        var callback = window.localStorage.getItem(storage_key);
        if (callback && typeof callback == 'string') {
            eval(callback);
            window.localStorage.removeItem(storage_key);
        }
    };

    return RedirectHelper;

})(RedirectHelper || null);

RedirectHelper.doAction();

var Follow = (function (parent) {
    var Follow = function (parent) {
    };
    Follow.options = {
        'set_url': '/api/v1/follow/set-follow',
        'unset_url': '/api/v1/follow/unset-follow'
    };
    Follow.setFollow = function (channel_id, callback) {
        data = { channel_id: channel_id };
        $.ajax({
            url: Follow.options.set_url,
            type: 'POST',
            dataType: "json",
            data: data,
            success: function (resp) {
                if (typeof callback == 'function') {
                    callback.call(this, resp);
                }
            }
        });
    };

    Follow.unsetFollow = function (channel_id, callback) {
        data = { channel_id: channel_id };
        $.ajax({
            url: Follow.options.unset_url,
            type: 'POST',
            dataType: "json",
            data: data,
            success: function (resp) {
                if (typeof callback == 'function') {
                    callback.call(this, resp);
                }
            }
        });
    };

    return Follow;
})(Follow || null);



var Auth = (function (parent) {
    // console.log('auth');
    var msg = "";
    var Auth = function (parent) {
    };
    Auth.options = {
        'url': {
            'hasIdentity': '/api/v1/auth/has-identity',
            'connectSocProfile': '/api/v1/auth/connect-social-profile',
            'checkout': '/store/checkout',
            'checkoutStep3': '/store/checkout#step-3',
            'redemption': '/api/v1/promo/use-redemption',
            'promo': '/api/v1/promo/set-promo',
            'promoRemove': '/api/v1/promo/remove-promo',
            'isntUserHasB2bRole': '/api/v1/b2b/isnt-user-has-b2b-role',
            'b2bRequest': '/api/v1/b2b/request'
        },
        'user': {
            'boxUserNotAuth': '.user-auth-not-identity',
            'boxUser': '.user-auth-box',
            'userName': '.user-auth-name',
            'userAvatar': '.user-auth-image',
            'userId': '.user-auth-id',
            'email': '.user-auth-email',
            'userState': '.user-auth-state',
            'hasPassword': '.user-auth-status-password'
        }
    };
    Auth.getMessage = function () {
        return msg;
    };
    Auth.setMessage = function (mess) {
        msg = mess;
    };
    Auth.connectSocialProfile = function (token, provider, callback) {
        var me = this;
        $.ajax({
            url: me.options.url.connectSocProfile,
            data: { 'access_token': token, provider: provider },
            method: 'POST',
            dataType: 'json',
            success: function (resp) {
                if (typeof callback == 'function') {
                    callback.call(this, resp.success, resp.message);
                }
                //console.info(resp);
            }
        })
    };
    Auth.useCode = function (code, type, page, callbackSuccess, callbackError) {
        if (!code) {
            if (typeof callbackError == 'function') {
                callbackError.call(this, false, locale['TEXT_PLEASE_ENTER_CODE']);
            }
            return false;
        }
        var me = this;
        var res = false;
        $.ajax({
            url: me.options.url[type],
            dataType: 'json',
            method: 'POST',
            data: { code: code },
            success: function (resp) {
                if (resp.success) {
                    $(window).trigger('store.enterCode', { type: resp.data.type, code: code, page: page });
                    if (typeof callbackSuccess == 'function') {
                        callbackSuccess.call(this, resp.success, resp.message, resp);
                    }
                    return true;
                } else {
                    if (typeof callbackError == 'function') {
                        callbackError.call(this, resp.success, resp.message, resp);
                    }
                    return false;
                }
            },
            complete: function () {
            }
        });
    };

    Auth.showLoginForm = function (target, callback, customTitle, popup) {
        // var clone = $('.join-window');
        var popup = new CnModal(popup);
        popup.show();

        $('.js-login-b2c').off('click').on('click', function (e) {
            e.preventDefault();
            var isVote = $(this).data('isvote');
            var isFollow = $(this).data('isfollow');
            var channelId = $(this).data('channel');
            var usertype = $(this).attr('data-usertype');
            var l = Ladda.create(this);
            l.start();
            var email = this.form.email.value;
            var password = this.form.password.value;
            var msg = '';
            Auth.login(email, password, function (data) {
                MA_LoginSuccess(e, usertype);
                l.stop();
                popup.hide();
                Auth.initPage();
                $('.user-auth-not-identity').hide();
                if (isVote == 1) {
                    $('.vote-btn').click();
                }
                if (isFollow == 1) {
                    $('.js-follow-channel[data-channel="' + channelId + '"]').click();
                }
                reloadContent("#user-menu-wrapper");
                if (typeof callback == 'function') {
                    callback.call(this, clone, data)
                }
            }, function (error) {
                l.stop()
                System.showErrors(error);

            });
        });

        /*        function attachSigninGoogle(elements) {
                    $(elements).each(function(index, element){
                        var isBuy = $(element).data('isbuy');
                        var isObt = $(element).data('obt');
                        var usertype = $(element).attr('data-usertype');
                        var isB2b = usertype === 'b2b' ? true : null;
                        var isPopup = $(element).data('ispopup');
                        var isFollow = $(element).data('isfollow');
                        var channelId = $(element).data('channel');
                        // console.log(channelId + ' channelId');
                        var isEmbed = $(target).hasClass('js-show-embed-popup');
                        var page = $('#page').val();
                        $(element).off('click').on('click',function(e){
                            var l = Ladda.create(element);
                            l.start();
                            var tokenResponse = auth2.requestAccessToken();
                            if(tokenResponse.error || !tokenResponse.access_token) {
                                if (tokenResponse.error_description=== 'popup_closed_by_user') {
                                    System.showErrors('Login has been cancelled');
                                } else {
                                    System.showErrors(tokenResponse.error_description);
                                }
                                return;
                            }
                            Auth.googleLogin(tokenResponse.access_token, function (r, data, msg) {
                                if (r) {
                                    MA_LoginGoogleEvent(element, data.isB2b, data.isNew);
        
                                    if(data.isNew){
                                        MA_JoinSuccess(element, usertype);
                                        // MA_JoinSuccessFBPixel(element, usertype);
                                    } else {
                                        MA_LoginSuccess(element, usertype);
                                    }
        
                                    var isVote = $('.js-login-from-goole').data('isvote');
        
                                    if (isVote == 1) {
                                        Auth.initPage();
                                        $('.user-auth-not-identity').hide();
                                        $('.vote-btn').click();
                                        $('.c-popup').fadeOut();
                                        if (data.isNew) {
                                            newTab(Config.BASE_PATH + "/user/welcome");
                                        }
                                        return true;
                                    }
                                    if (isFollow == 1) {
                                        Auth.initPage();
                                        $('.user-auth-not-identity').hide();
                                        $('.js-follow-channel[data-channel="'+channelId+'"]').click();
                                        $('.c-popup').fadeOut();
                                        return true;
                                    }
        
                                    if(isBuy){
                                        cart[isBuy](element, l);
                                        Auth.initPage();
                                        $('.user-auth-not-identity').hide();
                                    } else if (isObt) {
                                        l.stop();
                                        Auth.addTestBooks();
                                    }
                                    else if(isEmbed) {
                                        $('.join-window').hide();
                                        $('.user-auth-not-identity').hide();
                                        Auth.initPage();
                                        if (data.isNew) {
                                            System.notify("Your account has been successfully activated!");
                                        }
                                        var popup = $(target).data("popup");
                                        $("#" + popup).show();
                                    }
                                    else if (data.isNew) {
                                        location.href = Config.BASE_PATH + "/user/welcome";
                                    } else if (page == 'channel_subscribe') {
                                        $('#channel_subscribe_method').submit();
                                    } else if (isPopup) {
                                        $('.join-window').hide();
                                        $('.user-auth-not-identity').hide();
                                        Auth.initPage();
                                    } else {
                                        if(!!data.hash) {
                                            location.href = data.hash ? data.hash : "/user/projects";
                                        } else {
                                            location.href = "/user/profile";
                                        }
                                    }
                                } else {
                                    System.showErrors(msg);
                                    l.stop();
                                }
                            }, isB2b);
        
                        });
        /!*                auth2.attachClickHandler(element, {},
                            function (googleUser) {
                                var l = Ladda.create(element);
                                l.start();
                                Auth.googleLogin(googleUser.getAuthResponse().access_token, function (r, data, msg) {
                                    if (r) {
                                        MA_LoginGoogleEvent(element, data.isB2b, data.isNew);
        
                                        if(data.isNew){
                                            MA_JoinSuccess(element, usertype);
                                            // MA_JoinSuccessFBPixel(element, usertype);
                                        } else {
                                            MA_LoginSuccess(element, usertype);
                                        }                                
        
                                        var isVote = $('.js-login-from-goole').data('isvote');
                                        
                                        if (isVote == 1) {
                                            Auth.initPage();
                                            $('.user-auth-not-identity').hide();
                                            $('.vote-btn').click();
                                            $('.c-popup').fadeOut();
                                            if (data.isNew) {
                                                newTab(Config.BASE_PATH + "/user/welcome");
                                            }
                                            return true;
                                        }
                                        if (isFollow == 1) {
                                            Auth.initPage();
                                            $('.user-auth-not-identity').hide();
                                            $('.js-follow-channel[data-channel="'+channelId+'"]').click();
                                            $('.c-popup').fadeOut();
                                            return true;
                                        }
                                        
                                        if(isBuy){
                                            cart[isBuy](element, l);
                                            Auth.initPage();
                                            $('.user-auth-not-identity').hide();
                                        } else if (isObt) {
                                            l.stop();
                                            Auth.addTestBooks();
                                        }
                                        else if(isEmbed) {
                                            $('.join-window').hide();
                                            $('.user-auth-not-identity').hide();
                                            Auth.initPage();
                                            if (data.isNew) {
                                                System.notify("Your account has been successfully activated!");
                                            }
                                            var popup = $(target).data("popup");
                                            $("#" + popup).show();
                                        }
                                        else if (data.isNew) {
                                            location.href = Config.BASE_PATH + "/user/welcome";
                                        } else if (page == 'channel_subscribe') {
                                            $('#channel_subscribe_method').submit();
                                        } else if (isPopup) {
                                            $('.join-window').hide();
                                            $('.user-auth-not-identity').hide();
                                            Auth.initPage();
                                        } else {
                                            if(!!data.hash) {
                                                location.href = data.hash ? data.hash : "/user/projects";
                                            } else {
                                                location.href = "/user/profile";
                                            }
                                        }
                                    } else {
                                        System.showErrors(msg);
                                        l.stop();
                                    }
                                }, isB2b);
                            }, function (error) {
                                // console.log(error);
                                //toDo fix errors!!!
                                if (error.error === 'popup_closed_by_user') {
                                    System.showErrors('Login has been cancelled');
                                } else {
                                    System.showErrors(error.reason);
                                }
                            }
                        );*!/
                    });
                }*/

        function handleGoogleTokenResponseCallback(tokenResponse) {
            var element = $('.js-login-from-goole');
            var isBuy = $(element).data('isbuy');
            var isObt = $(element).data('obt');
            var usertype = $(element).attr('data-usertype');
            var isB2b = usertype === 'b2b' ? true : null;
            var isPopup = $(element).data('ispopup');
            var isFollow = $(element).data('isfollow');
            var channelId = $(element).data('channel');
            // console.log(channelId + ' channelId');
            var isEmbed = $(target).hasClass('js-show-embed-popup');
            var page = $('#page').val();

            Auth.googleLogin(tokenResponse.access_token, function (r, data, msg) {
                if (r) {
                    MA_LoginGoogleEvent(element, data.isB2b, data.isNew);

                    if (data.isNew) {
                        MA_JoinSuccess(element, usertype);
                        // MA_JoinSuccessFBPixel(element, usertype);
                    } else {
                        MA_LoginSuccess(element, usertype);
                    }

                    var isVote = $('.js-login-from-goole').data('isvote');

                    if (isVote == 1) {
                        Auth.initPage();
                        $('.user-auth-not-identity').hide();
                        $('.vote-btn').click();
                        $('.c-popup').fadeOut();
                        if (data.isNew) {
                            newTab(Config.BASE_PATH + "/user/welcome");
                        }
                        return true;
                    }
                    if (isFollow == 1) {
                        Auth.initPage();
                        $('.user-auth-not-identity').hide();
                        $('.js-follow-channel[data-channel="' + channelId + '"]').click();
                        $('.c-popup').fadeOut();
                        return true;
                    }

                    if (isBuy) {
                        cart[isBuy](element, loaderForGoogleButton);
                        Auth.initPage();
                        $('.user-auth-not-identity').hide();
                    } else if (isObt) {
                        loaderForGoogleButton.stop();
                        Auth.addTestBooks();
                    }
                    else if (isEmbed) {
                        $('.join-window').hide();
                        $('.user-auth-not-identity').hide();
                        Auth.initPage();
                        if (data.isNew) {
                            System.notify("Your account has been successfully activated!");
                        }
                        var popup = $(target).data("popup");
                        $("#" + popup).show();
                    }
                    else if (data.isNew) {
                        location.href = Config.BASE_PATH + "/user/welcome";
                    } else if (page == 'channel_subscribe') {
                        $('#channel_subscribe_method').submit();
                    } else if (isPopup) {
                        $('.join-window').hide();
                        $('.user-auth-not-identity').hide();
                        Auth.initPage();
                    } else {
                        if (!!data.hash) {
                            location.href = data.hash ? data.hash : "/user/projects";
                        } else {
                            location.href = "/user/profile";
                        }
                    }
                } else {
                    System.showErrors(msg);
                    loaderForGoogleButton.stop();
                }
            }, isB2b);
        }

        var startApp = function () {
            /*            gapi.load('auth2', function () {
                            // Retrieve the singleton for the GoogleAuth library and set up the client.
                            auth2 = gapi.auth2.init({
                                client_id: google_client_id,
                                cookiepolicy: 'single_host_origin',
                                // Request scopes in addition to 'profile' and 'email'
                                scope: 'email' // https://www.googleapis.com/auth/userinfo.profile
                            });
            //            var bLogin = document.getElementsByClassName('google-button-login');
                            var elements = $('.js-login-from-goole'); // document.getElementsByClassName('google-button-sign');
                            if(elements.length > 0){
                                // console.log(elements.length);
                                attachSigninGoogle(elements);
                            }
                        });*/
            var googleTokenClient = {};
            var loaderForGoogleButton = '';

            window.onload = function () {
                // Retrieve the singleton for the GoogleAuth library and set up the client.
                googleTokenClient = google.accounts.oauth2.initTokenClient({
                    client_id: google_client_id,
                    prompt: 'select_account',
                    //cookiepolicy: 'single_host_origin',
                    /*                    callback: (tokenResponse) => {
                                            var element = $('.js-login-from-goole');
                                            var isBuy = $(element).data('isbuy');
                                            var isObt = $(element).data('obt');
                                            var usertype = $(element).attr('data-usertype');
                                            var isB2b = usertype === 'b2b' ? true : null;
                                            var isPopup = $(element).data('ispopup');
                                            var isFollow = $(element).data('isfollow');
                                            var channelId = $(element).data('channel');
                                            // console.log(channelId + ' channelId');
                                            var isEmbed = $(target).hasClass('js-show-embed-popup');
                                            var page = $('#page').val();
                    
                                            Auth.googleLogin(tokenResponse.access_token, function (r, data, msg) {
                                                if (r) {
                                                    MA_LoginGoogleEvent(element, data.isB2b, data.isNew);
                    
                                                    if(data.isNew){
                                                        MA_JoinSuccess(element, usertype);
                                                        // MA_JoinSuccessFBPixel(element, usertype);
                                                    } else {
                                                        MA_LoginSuccess(element, usertype);
                                                    }
                    
                                                    var isVote = $('.js-login-from-goole').data('isvote');
                    
                                                    if (isVote == 1) {
                                                        Auth.initPage();
                                                        $('.user-auth-not-identity').hide();
                                                        $('.vote-btn').click();
                                                        $('.c-popup').fadeOut();
                                                        if (data.isNew) {
                                                            newTab(Config.BASE_PATH + "/user/welcome");
                                                        }
                                                        return true;
                                                    }
                                                    if (isFollow == 1) {
                                                        Auth.initPage();
                                                        $('.user-auth-not-identity').hide();
                                                        $('.js-follow-channel[data-channel="'+channelId+'"]').click();
                                                        $('.c-popup').fadeOut();
                                                        return true;
                                                    }
                    
                                                    if(isBuy){
                                                        cart[isBuy](element, l);
                                                        Auth.initPage();
                                                        $('.user-auth-not-identity').hide();
                                                    } else if (isObt) {
                                                        l.stop();
                                                        Auth.addTestBooks();
                                                    }
                                                    else if(isEmbed) {
                                                        $('.join-window').hide();
                                                        $('.user-auth-not-identity').hide();
                                                        Auth.initPage();
                                                        if (data.isNew) {
                                                            System.notify("Your account has been successfully activated!");
                                                        }
                                                        var popup = $(target).data("popup");
                                                        $("#" + popup).show();
                                                    }
                                                    else if (data.isNew) {
                                                        location.href = Config.BASE_PATH + "/user/welcome";
                                                    } else if (page == 'channel_subscribe') {
                                                        $('#channel_subscribe_method').submit();
                                                    } else if (isPopup) {
                                                        $('.join-window').hide();
                                                        $('.user-auth-not-identity').hide();
                                                        Auth.initPage();
                                                    } else {
                                                        if(!!data.hash) {
                                                            location.href = data.hash ? data.hash : "/user/projects";
                                                        } else {
                                                            location.href = "/user/profile";
                                                        }
                                                    }
                                                } else {
                                                    System.showErrors(msg);
                                                    l.stop();
                                                }
                                            }, isB2b);
                                        },*/
                    callback: handleGoogleTokenResponseCallback,
                    error_callback: (error) => {
                        console.log(error);
                        loaderForGoogleButton.stop();
                        if (error.type === 'popup_closed') {
                            System.showErrors('Login has been cancelled');
                        } else {
                            System.showErrors(error.message);
                        }
                    },
                    // Request scopes in addition to 'profile' and 'email'
                    scope: 'email' // https://www.googleapis.com/auth/userinfo.profile
                });

                $('.js-login-from-goole').off('click').on('click', function (e) {
                    loaderForGoogleButton = Ladda.create(this);
                    loaderForGoogleButton.start();
                    googleTokenClient.requestAccessToken();
                });
                //            var bLogin = document.getElementsByClassName('google-button-login');
            };
        };

        startApp();

        $('.login-popup .facebook-popup').off('click').on('click', function (e) {

            e.preventDefault();
            var l = Ladda.create(this);
            var isVote = $(this).data('isvote');
            var isFollow = $(this).data('isfollow');
            var channelId = $(this).data('channel');
            var usertype = $(this).attr('data-usertype');
            var isEmbed = $(target).hasClass('js-show-embed-popup');
            l.start();

            var tw = hello("facebook").getAuthResponse();

            hello.login('facebook', { scope: 'publish,email,user_link' }, function (resp) {
                if (resp && resp.error) {
                    if (resp.error.code === 'access_denied') {
                        System.showErrors('Login has been cancelled');
                    } else {
                        System.showErrors(resp.error.message);
                    }
                    return;
                }
                Auth.fbLogin(resp.authResponse.access_token, function (r, data, msg) {
                    l.stop();
                    if (r) {
                        MA_LoginFacebookEvent(e, data.isB2b, data.isNew);

                        if (data.isNew) {
                            MA_JoinSuccess(e, usertype);
                            // MA_JoinSuccessFBPixel(e, usertype);
                        } else {
                            MA_LoginSuccess(e, usertype);
                        }
                        popup.hide();
                        Auth.initPage();
                        $('.user-auth-not-identity').hide();
                        if (isVote) {
                            $('.vote-btn').click();
                        }
                        if (isFollow) {
                            $('.js-follow-channel[data-channel="' + channelId + '"]').click();
                        }
                        if (isEmbed) {
                            $('.join-window').hide();
                            $('.user-auth-not-identity').hide();
                            Auth.initPage();
                            if (data.isNew) {
                                System.notify("Your account has been successfully activated!");
                            }
                            var embedPopup = $(target).data("popup");
                            $("#" + embedPopup).show();
                            return;
                        }
                        if (data.isNew) {
                            newTab(Config.BASE_PATH + "/user/welcome");
                        }
                        if (typeof callback == 'function') {
                            callback.call(this, clone, data)
                        }
                    } else {
                        System.showErrors(msg);
                    }
                })
            });
        });

        $('.js-join-b2c').off('click').on('click', function (e) {
            e.preventDefault();
            var l = Ladda.create(this);
            l.start();
            var email = this.form.email.value;
            var password = this.form.password.value;
            var isVote = $(this).data('isvote');
            var isFollow = $(this).data('isfollow');
            var channelId = $(this).data('channel');
            var usertype = $(this).attr('data-usertype');
            var terms = this.form.agree_terms.checked;

            var msg = '';

            if (!terms) {
                System.showErrors(TEXT_TO_USE_PP_AND_TOS);
                l.stop();
                return;
            }

            Auth.register(email, password, null, function (result, message) {
                if (result) {

                    MA_JoinSuccess(e, usertype);

                    Auth.login(email, password, function () {
                        l.stop();
                        popup.hide();
                        Auth.initPage();
                        $('.user-auth-not-identity').hide();
                        if (isVote == 1) {
                            $('.vote-btn').click();
                        }
                        if (isFollow == 1) {
                            $('.js-follow-channel[data-channel="' + channelId + '"]').click();
                        }
                        reloadContent("#user-menu-wrapper");
                        if (typeof callback === 'function') {
                            callback.call(this, result);
                        }
                        window.authService.hasIdentity(null, true);
                    });
                    if (message) {
                        show_alert(message, 'success');
                    }
                } else {
                    System.error(message);
                    l.stop();
                }
            }, isVote);
        });

        $('.login-popup .forgot-password-btn').off('click').on('click', function (e) {
            e.preventDefault();
            var url = window.Config.BASE_PATH + "/user/login#restore-password";
            var backUrl = location.href;
            if (window.localStorage) {
                window.localStorage.setItem('registration_return_url', backUrl);
            }
            location.href = url;
        });

        if (customTitle) {
            $('.login-popup .panel-body .title').text(customTitle).show();
        } else {

        }

    };

    Auth.hideLoginForm = function (form) {
        var modal = (new CnModal(form.parents('.popup.box'))).hide();
    }

    Auth.showRegisterForm = function (popup, callback, customTitle) {
        // var clone = $('.join-window');
        console.log(popup);
        var popup = new CnModal(popup);
        popup.show();

        $('.js-login-b2c').off('click').on('click', function (e) {
            e.preventDefault();
            //  System.hideErrors()
            var l = Ladda.create(this);
            l.start();
            var email = this.form.email.value;
            var password = this.form.password.value;
            var msg = '';
            var usertype = $(this).attr('data-usertype');

            Auth.login(email, password, function (data) {
                MA_LoginSuccess(e, usertype);
                l.stop();
                popup.hide();
                Auth.initPage();
                $('.user-auth-not-identity').hide();
                if (typeof callback == 'function') {
                    callback.call(this, clone, data)
                }
                //window.location.reload(true); {removed to avoid reload page after login}
            }, function (error) {
                l.stop()
                System.showErrors(error);

            });
        });

        $('.js-join-b2c').off('click').on('click', function (e) {
            e.preventDefault();
            //  System.hideErrors()
            var l = Ladda.create(this);
            l.start();
            var email = this.form.email.value;
            var password = this.form.password.value;
            var terms = this.form.agree_terms.checked;
            var msg = ''
            var usertype = $(this).attr('data-usertype');

            if (!terms) {
                System.showErrors(TEXT_TO_USE_PP_AND_TOS);
                l.stop();
                return;
            }

            Auth.checkEmail(email, function (r, msg) {
                if (!r) {
                    l.stop();
                    System.error(msg);
                } else {
                    Auth.register(email, password, null, function (result, message) {
                        if (result) {
                            MA_JoinSuccess(e, usertype);
                            // MA_JoinSuccessFBPixel(e, usertype);

                            Auth.login(email, password, function () {
                                l.stop();
                                popup.hide();
                                Auth.initPage();
                                $('.user-auth-not-identity').hide();
                                //                                window.open("/user/profile");
                                if (typeof callback === 'function') {
                                    callback.call(this, result);
                                }

                                window.authService.hasIdentity(null, true);
                                //                                window.open(Config.BASE_PATH +"/user/projects", '_blank');
                            });

                            show_alert(message, 'success');
                        } else {
                            System.error(message);
                            l.stop();
                        }

                    });
                }
            });
        });


        $('.login-popup .facebook-popup').off('click').on('click', function (e) {

            e.preventDefault();
            var l = Ladda.create(this);
            l.start();

            var tw = hello("facebook").getAuthResponse();

            hello.login('facebook', { scope: 'publish,email,user_link' }, function (resp) {
                //                l.stop();
                if (resp && resp.error) {
                    if (resp.error.code === 'access_denied') {
                        System.showErrors('Login has been cancelled');
                    } else {
                        System.showErrors(resp.error.message);
                    }
                    return;
                }
                Auth.fbLogin(resp.authResponse.access_token, function (r, data, msg) {
                    l.stop();
                    if (r) {
                        MA_LoginFacebookEvent(e, data.isB2b, data.isNew);

                        if (data.isNew) {
                            MA_JoinSuccess(e, usertype);
                            // MA_JoinSuccessFBPixel(e, usertype);
                        } else {
                            MA_LoginSuccess(e, usertype);
                        }
                        Auth.initPage();
                        popup.hide();

                        if (typeof callback == 'function') {
                            callback.call(this, clone, data)
                        }
                    } else {
                        System.showErrors(msg);
                    }
                })
            });
        });

        $('.login-popup .forgot-password-btn').off('click').on('click', function (e) {
            e.preventDefault();
            var url = window.Config.BASE_PATH + "/user/login#restore-password";
            var backUrl = location.href;
            if (window.localStorage) {
                window.localStorage.setItem('registration_return_url', backUrl);
            }
            location.href = url;
        });

        if (customTitle) {
            $('.login-popup .panel-body .title').text(customTitle).show();
        } else {
            //            $('.login-popup .panel-body .title').hide();
        }

    };

    Auth.register = function (email, password, password2, callback, vote) {
        var res = false;
        var page = $('#page').val();
        window.grecaptcha.execute(window.reCAPTCHA_site_key, { action: 'register' })
            .then(function (token) {
                // console.log('%cToken', 'color: gold', token);
                $.ajax({
                    url: window.Config.BASE_PATH + '/api/v1/auth/register',
                    dataType: 'json',
                    type: 'post',
                    method: 'POST',
                    data: {
                        email: email,
                        password: password,
                        password2: password2,
                        'g-recaptcha-response': token,
                        page: page
                    },
                    success: function (resp) {
                        // grecaptcha.reset(captchaRegId);
                        if (resp.success) {
                            Ladda.stopAll();
                            if (typeof callback == "function") {
                                var res = callback.call(this, resp.success, resp.message);
                                return;
                            }
                            if (!resp.data.state) {
                                newTab(Config.BASE_PATH + "/user/profile");
                            }
                            if (vote) {
                                $('.vote-btn').click();
                            }
                        } else {
                            Ladda.stopAll();
                            show_alert(resp.message, 'error');
                        }
                        System.loadingHide();
                    },
                    complete: function () {

                    }
                });
            });

        return res;
    };

    Auth.registerObt = function (email, password, callback) {
        var res = false;
        window.grecaptcha.execute(window.reCAPTCHA_site_key, { action: 'register' })
            .then(function (token) {
                $.ajax({
                    url: window.Config.BASE_PATH + '/api/v1/auth/register-obt',
                    dataType: 'json',
                    type: 'post',
                    method: 'POST',
                    data: { email: email, password: password, 'g-recaptcha-response': token },
                    success: function (resp) {
                        // grecaptcha.reset(captchaRegId);
                        if (!resp.success) {
                            Ladda.stopAll();
                            show_alert(resp.message, 'error');
                        }
                        if (typeof callback == "function") {
                            var res = callback.call(this, resp.success, resp.message);
                        }
                        System.loadingHide();
                    },
                    complete: function () {
                    }
                });

            });
        return res;
    };

    Auth.checkEmail = function (email, callback, needCheck) {
        if (needCheck == undefined) { needCheck = true; }
        if (needCheck) {
            //  System.hideErrors();
            var me = this;
            var res = false;
            $.ajax({
                url: window.Config.BASE_PATH + '/api/v1/auth/check-email',
                dataType: 'json',
                async: false,
                method: 'POST',
                data: { email: email },
                success: function (resp) {
                    if (!resp.success) {
                        Ladda.stopAll();
                        show_alert(resp.message, 'error');
                        res = false;
                        //  System.showErrors(resp.message);
                    } else {
                        res = true;
                    }
                    if (typeof callback == "function") {
                        var res = callback.call(this, resp.success, resp.message);
                    }
                },
                complete: function () {
                    System.loadingHide();
                }
            })
        } else {
            if (typeof callback == "function") {
                var res = callback.call(this, true);
            }

        }
        return res;
    };

    Auth.simpleCheckEmail = function (email) {
        var res = false;
        $.ajax({
            url: '/api/v1/auth/check-email',
            dataType: 'json',
            async: false,
            method: 'POST',
            data: { email: email },
            success: function (resp) {
                if (!resp.success) {
                    res = false;
                } else {
                    res = true;
                }
            }
        });
        return res;
    };

    Auth.checkNickname = function (nickname, callback) {
        //  System.hideErrors();
        var me = this;
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/check-nickname',
            dataType: 'json',
            method: 'POST',
            data: { nickname: nickname },
            success: function (resp) {
                if (typeof callback == "function") {
                    callback.call(this, resp.success, resp.message);
                }
            },
            complete: function () {
                System.loadingHide();
            }
        })
    };

    Auth.sendConfirm = function (id, callback) {
        //  System.hideErrors();
        $(window).trigger('profile.sendConfirm', { userId: id });
        var me = this;
        var res = false;
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/send-confirm',
            dataType: 'json',
            method: 'POST',
            data: { id: id },
            success: function (resp) {
                if (!resp.success) {
                    res = false;
                    System.showErrors(resp.message);
                } else {
                    res = true;
                }
                if (typeof callback == 'function') {
                    callback.call(this, resp.success, resp.message, resp);
                }

            },
            complete: function () {

                System.loadingHide();
            }
        })
    };
    Auth.sendChangeEmailConfirm = function (id, callback) {
        //  System.hideErrors();
        $(window).trigger('profile.sendChangeEmailConfirm', { userId: id });
        var me = this;
        var res = false;
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/send-email-change-confirm',
            dataType: 'json',
            method: 'POST',
            data: { id: id },
            success: function (resp) {
                if (!resp.success) {
                    res = false;
                    // System.showErrors(resp.message);
                } else {
                    res = true;
                }
                if (typeof callback == 'function') {
                    callback.call(this, resp.success, resp.message);
                }

            },
            complete: function () {
                System.loadingHide();
            }
        })
    };

    Auth.checkConfirm = function (id, callback) {
        //  System.hideErrors();
        var me = this;
        var res = false;
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/check-confirm',
            dataType: 'json',
            method: 'POST',
            data: { id: id },
            success: function (resp) {
                if (!resp.success) {
                    res = false;
                    // System.showErrors(resp.message);
                } else {
                    res = true;
                }
                if (typeof callback == 'function') {
                    callback.call(this, resp.success, resp.message);
                }

            },
            complete: function () {
                System.loadingHide();
            }
        })
    };

    Auth.confirmEmailForObt = function (callback) {
        //  System.hideErrors();
        var me = this;

        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/confirm-email-for-obt',
            dataType: 'json',
            method: 'POST',
            success: function (resp) {
                if (!resp.success) {
                    res = false;
                    // System.showErrors(resp.message);
                } else {
                    res = true;
                }
                if (typeof callback == 'function') {
                    callback.call(this, resp.success, resp.message);
                }

            },
            complete: function () {
                System.loadingHide();
            }
        })
    };

    Auth.login = function (email, password, callbackSuccess, callbackFail) {
        // System.hideErrors();
        var res = false;
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/login',
            dataType: 'json',
            method: 'POST',
            data: { email: email, password: password },
            success: function (resp) {
                if (resp.success) {
                    if (typeof callbackSuccess == 'function') {
                        return callbackSuccess.call(this, resp.data);
                    }
                    window.authService.hasIdentity(null, true);
                } else {
                    if (resp.data.not_confirmed) {
                        location.href = "/user/confirm-wait?id=" + resp.data.id;
                        return;
                    }
                    if (typeof callbackFail == 'function') {
                        Ladda.stopAll();
                        show_alert(resp.message, 'error');
                        return callbackFail.call(this, resp.message, resp.code);
                    }
                }
            },
            complete: function () {

            }
        });
        return res;
    };
    Auth.addTestBooks = function (callback) {
        // System.hideErrors();
        var res = false;
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/promo/set-obt-user',
            dataType: 'json',
            method: 'POST',
            success: function (resp) {
                if (resp.success) {
                    show_alert(resp.message, 'success');
                    if (typeof callback == 'function') {
                        callback.call(this, resp.resp, resp.message);
                    }
                } else if (resp.code == 30006) {
                    show_alert(resp.data.obt_message.msg, 'success');
                    if (typeof callback == 'function') {
                        callback.call(this, resp.resp, resp.message);
                    }
                }
            },
            complete: function () {

            }
        });
        return res;
    };
    Auth.mergeAccounts = function (params, callback) {
        // System.hideErrors();
        var res = false;
        var email,
            password,
            token,
            accessToken,
            provider = null;

        if (typeof params['email'] != 'undefined') {
            email = params['email'];
            password = params['password'];
            token = params['token'];
        } else if (typeof params['accessToken'] != 'undefined') {
            accessToken = params['accessToken'];
            provider = params['provider'];
        }
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/merge-account',
            dataType: 'json',
            type: 'post',
            data: { email: email, password: password, token: token, accessToken: accessToken, provider: provider },
            success: function (resp) {
                if (typeof callback == 'function') {
                    callback.call(this, resp.resp, resp.message);
                }
            },
            complete: function () {

            }
        });
        return res;
    };
    Auth.fbLogin = function (token, callback, isB2b) {
        // console.log('callback', callback);
        //   System.hideErrors();
        var res = false;
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/fb-login',
            dataType: 'json',
            method: 'POST',
            data: { token: token, is_b2b: isB2b },
            success: function (resp) {
                if (resp.success) {
                    if (resp.data.competitionRedirect) {
                        location.href = resp.data.competitionRedirect;
                        return;
                    }
                    if (typeof callback == 'function') {
                        return callback.call(this, resp.success, resp.data);
                    }
                    window.authService.hasIdentity(null, true);
                } else {
                    if (resp.data.not_confirmed) {
                        location.href = "/user/confirm-wait?id=" + resp.data.id;
                        return;
                    }
                    if (typeof callback == 'function') {
                        return callback.call(this, resp.success, resp.data, resp.message);
                    }
                }
            },
            complete: function () {

            }
        });
        return res;
    };

    Auth.googleLogin = function (token, callback, isB2b) {
        //   System.hideErrors();
        var res = false;
        $.ajax({
            url: '/api/v1/auth/google-login',
            dataType: 'json',
            method: 'POST',
            data: { token: token, is_b2b: isB2b },
            success: function (resp) {
                if (resp.success) {
                    if (resp.data.competitionRedirect) {
                        location.href = resp.data.competitionRedirect;
                        return;
                    }
                    if (typeof callback == 'function') {
                        return callback.call(this, resp.success, resp.data);
                    }
                    window.authService.hasIdentity(null, true);
                } else {
                    if (resp.data.not_confirmed) {
                        location.href = "/user/confirm-wait?id=" + resp.data.id;
                        return;
                    }
                    if (typeof callback == 'function') {
                        return callback.call(this, resp.success, resp.data, resp.message);
                    }
                }
            },
            complete: function () {

            }
        });
        return res;
    };
    Auth.logout = function (callback) {
        // System.hideErrors();
        $.ajax({
            url: window.Config.BASE_PATH + '/user/logout',
            dataType: 'html',
            method: 'POST',
            success: function (resp) {
                if (typeof callback == 'undefined') {
                    callback.call();
                }
            },
            complete: function () {

            }
        })
    };
    Auth.changePassword = function (passwordOld, passwordNew, passwordVerify, passwordCreate, callback) {
        var res = false;
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/change-password',
            dataType: 'json',
            method: 'POST',
            data: {
                passwordOld: passwordOld,
                token: passwordOld.length == 32 ? passwordOld : null,
                passwordNew: passwordNew,
                passwordVerify: passwordVerify,
                passwordCreate: passwordCreate
            },
            success: function (resp) {
                if (typeof callback == 'function') {
                    return callback.call(this, resp.success, resp.message);
                }
            },
            complete: function () {
                System.loadingHide();
                $('form input[type="password"]').val('');
            }
        });

        return res;
    };
    Auth.deactivateRestoreToken = function (token, callback) {
        var res = false;
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/deactivate-restore-password-token',
            dataType: 'json',
            method: 'POST',
            data: {
                token: token,
            },
            success: function (resp) {
                if (typeof callback == 'function') {
                    return callback.call(this, resp.success, resp.message);
                }
            },
            complete: function () {
                System.loadingHide();
                $('form input[type="password"]').val('');
            }
        });

        return res;
    };
    Auth.changeEmail = function (email, callback) {
        var res = false;
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/change-email',
            dataType: 'json',
            method: 'POST',
            data: {
                email: email
            },
            success: function (resp) {
                if (typeof callback == 'function') {
                    callback.call(this, resp.success, resp.message);
                    //                    show_alert(resp.message, 'success');
                }
                //                if(!resp.success){
                //                    callback.call(this, null,resp.message);
                ////                    show_alert(resp.message, 'error');
                //                  //  System.showErrors(resp.message);
                //                }
            },
            complete: function () {
                Ladda.stopAll();
            }
        });
        return res;
    };
    Auth.changePaypalEmail = function (email, callback) {
        var res = false;
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/change-paypal-email',
            dataType: 'json',
            type: 'post',
            data: {
                email: email
            },
            success: function (resp) {
                if (typeof callback == 'function') {
                    callback.call(this, resp.success, resp.message);
                }
                if (!resp.success) {
                    System.showErrors(resp.message);
                }
            }
        });
        return res;
    };
    Auth.restorePassword = function (email, callback) {
        if (!email) {
            System.showErrors(locale['TEXT_ENTER_EMAIL']);
            return false;
        }
        var me = this;
        var res = false;
        $.ajax({
            url: window.Config.BASE_PATH + '/api/v1/auth/send-confirm-restore-password',
            dataType: 'json',
            type: 'post',
            data: { email: email },
            success: function (resp) {
                if (!resp.success) {
                    res = false;
                    //                    System.showErrors(resp.message);
                    $('.forgot-username').removeClass('success').addClass('error');
                } else {
                    res = true;
                    //                    System.notify('Thank you! The message has been sent to entered email address. Check your email');
                }
                if (typeof callback == 'function') {
                    callback.call(this, resp.success, resp.message);
                }
            },
            complete: function () {
                System.loadingHide();
            }
        });
    };
    Auth.initProfileDropdown = function () {
        if (typeof $('.login_links .dropdown-toggle').dropdown == 'function' && !$('.login_links .dropdown-toggle').hasClass('init')) {
            $('.login_links .dropdown-toggle').dropdown();
            $('.login_links .dropdown-toggle').addClass('init');
            $('.login_links .dropdown-toggle').hover(function () {
                //                alert('s')
                if (typeof $(this).dropdown == 'function') {
                    $(this).dropdown('toggle');
                }
            }, function () {
                //            $(this).click();
            })
            $('.login_links .clickable').on('click', function (e) {
                e.preventDefault();
                var href = $(this).data('href');
                window.location = href;
            });
        }
    };
    Auth.sendContact = function (email, text, name, subject, type, callback) {
        var me = this;
        var res = false;

        window.grecaptcha.execute(window.reCAPTCHA_site_key, { action: 'register' })
            .then(function (token) {

                $.ajax({
                    url: window.Config.BASE_PATH + '/api/v1/auth/send-contact',
                    dataType: 'json',
                    async: false,
                    data: { email: email, text: text, name: name, subject: subject, 'g-recaptcha-response': token },
                    success: function (resp) {
                        // grecaptcha.reset(recaptchaFaqId);
                        if (!resp.success) {
                            res = false;
                            if (resp.code == '1000') {
                                $('#contact-form, #contact-us').find('input[type="email"]').removeClass('success').addClass('error');
                            }
                        } else {
                            res = true;
                        }
                        if (typeof callback == "function") {
                            var res = callback.call(this, res, resp.message);
                        }
                    },
                    complete: function () {
                        System.loadingHide();
                    }
                });
            })
        return res;
    };
    Auth.hasIdentity = function (callback) {
        var res = false;
        $.ajax({
            url: Auth.options.url.hasIdentity,
            dataType: 'json',
            type: 'post',
            success: function (resp) {
                if (resp.success) {
                    Auth.identity = resp.data.identity;
                } else {
                    Auth.identity = Auth.emptyIdentity;
                }
                var obj = { 'success': resp.success, 'identity': Auth.getIdentity() };
                if (typeof callback == "function") {
                    callback.call(this, resp.success, resp, Auth);
                }

                // get user key
                console.log('Auth.hasIdentity get user key');
                var urlGetUserKey = window.Config.BASE_PATH + '/api/v1/auth/get-user-key';
                console.log('urlGetUserKey ' + urlGetUserKey);

                $.get(urlGetUserKey, function (tokenData) {
                    var userKey = tokenData.data.userKey;
                    console.log('get userKey from server ' + userKey);
                    dispatchLoggedInEvent(userKey);
                });

                //Auth.trigger('auth:hasIdentity', obj);
                $(window).trigger('auth:hasIdentity', obj);
            }
        });
        return res;
    };
    Auth.isntUserHasB2bRole = function (callbackSuccess, callbackError) {
        $.ajax({
            url: Auth.options.url.isntUserHasB2bRole,
            dataType: 'json',
            type: 'post',
            success: function (resp) {
                if (resp.success) {
                    if (typeof callbackSuccess == "function") {
                        callbackSuccess.call(this, resp);
                    }
                } else {
                    if (typeof callbackError == "function") {
                        callbackError.call(this, resp);
                    }
                }
                Ladda.stopAll();
            }
        });
    };
    Auth.b2bRequest = function (data, callback) {
        $.ajax({
            url: Auth.options.url.b2bRequest,
            type: 'post',
            dataType: 'json',
            data: data,
            success: function (response) {
                if (typeof callback == "function") {
                    var res = callback.call(this, response);
                }
            }
        });
    };
    Auth.initPage = function () {
        var me = this;
        me.hasIdentity(function (res, resp) {
            if (res) {
                var userData = resp.data.identity;
                $(me.options.user.userName).html(userData.userName);
                $(me.options.user.userAvatar).attr('src', userData.userAvatar);
                $(me.options.user.boxUser).show();
                $('.user-auth-not-identity').hide();
                if (userData.isB2BUser) {
                    $('.author-project-page-link').show();
                } else {
                    $('.author-project-page-link').hide();
                }
            } else {
                $(me.options.user.boxUserNotAuth).show();
            }
        });
    };
    Auth.emptyIdentity = {
        userId: null,
        userName: null,
        state: false,
        avatar: '/new_img/none.png',
        firstName: '',
        lastName: '',
        email: '',
        hasPassword: false
    };
    Auth.identity = {
        userId: null,
        userName: null,
        state: false,
        avatar: '/new_img/none.png',
        firstName: '',
        lastName: '',
        email: '',
        hasPassword: false
    };
    Auth.getIdentity = function () {
        return {
            userId: Auth.identity.userId,
            userName: Auth.identity.userName,
            state: Auth.identity.userState,
            avatar: Auth.identity.userAvatar,
            firstName: Auth.identity.firstName,
            lastName: Auth.identity.lastName,
            email: Auth.identity.email,
            profile: Auth.identity.profile,
            hasPassword: Auth.identity.hasPassword,
            hasIdentity: function () {
                return this.userId != null;
            },
            isConfirmed: function () {
                return this.hasIdentity() && Auth.identity.state;
            }
        }
    };

    // console.log('init auth');
    Auth.initPage();

    return Auth;
})(Auth || null);

window.authService = Auth;

function utf8_encode(str_data) {	// Encodes an ISO-8859-1 string to UTF-8
    //
    // +   original by: Webtoolkit.info (http://www.webtoolkit.info/)

    str_data = str_data.replace(/\r\n/g, "\n");
    var utftext = "";

    for (var n = 0; n < str_data.length; n++) {
        var c = str_data.charCodeAt(n);
        if (c < 128) {
            utftext += String.fromCharCode(c);
        } else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
        } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
        }
    }

    return utftext;
}

function md5(str) {	// Calculate the md5 hash of a string
    //
    // +   original by: Webtoolkit.info (http://www.webtoolkit.info/)
    // + namespaced by: Michael White (http://crestidg.com)

    if (typeof str != 'string') {
        str = JSON.stringify(str);
    }
    var RotateLeft = function (lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
    };

    var AddUnsigned = function (lX, lY) {
        var lX4, lY4, lX8, lY8, lResult;
        lX8 = (lX & 0x80000000);
        lY8 = (lY & 0x80000000);
        lX4 = (lX & 0x40000000);
        lY4 = (lY & 0x40000000);
        lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
        if (lX4 & lY4) {
            return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
        }
        if (lX4 | lY4) {
            if (lResult & 0x40000000) {
                return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
            } else {
                return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
            }
        } else {
            return (lResult ^ lX8 ^ lY8);
        }
    };

    var F = function (x, y, z) { return (x & y) | ((~x) & z); };
    var G = function (x, y, z) { return (x & z) | (y & (~z)); };
    var H = function (x, y, z) { return (x ^ y ^ z); };
    var I = function (x, y, z) { return (y ^ (x | (~z))); };

    var FF = function (a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    };

    var GG = function (a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    };

    var HH = function (a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    };

    var II = function (a, b, c, d, x, s, ac) {
        a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
        return AddUnsigned(RotateLeft(a, s), b);
    };

    var ConvertToWordArray = function (str) {
        var lWordCount;
        var lMessageLength = str.length;
        var lNumberOfWords_temp1 = lMessageLength + 8;
        var lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
        var lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
        var lWordArray = Array(lNumberOfWords - 1);
        var lBytePosition = 0;
        var lByteCount = 0;
        while (lByteCount < lMessageLength) {
            lWordCount = (lByteCount - (lByteCount % 4)) / 4;
            lBytePosition = (lByteCount % 4) * 8;
            lWordArray[lWordCount] = (lWordArray[lWordCount] | (str.charCodeAt(lByteCount) << lBytePosition));
            lByteCount++;
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
    };

    var WordToHex = function (lValue) {
        var WordToHexValue = "", WordToHexValue_temp = "", lByte, lCount;
        for (lCount = 0; lCount <= 3; lCount++) {
            lByte = (lValue >>> (lCount * 8)) & 255;
            WordToHexValue_temp = "0" + lByte.toString(16);
            WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
        }
        return WordToHexValue;
    };

    var x = Array();
    var k, AA, BB, CC, DD, a, b, c, d;
    var S11 = 7, S12 = 12, S13 = 17, S14 = 22;
    var S21 = 5, S22 = 9, S23 = 14, S24 = 20;
    var S31 = 4, S32 = 11, S33 = 16, S34 = 23;
    var S41 = 6, S42 = 10, S43 = 15, S44 = 21;

    str = this.utf8_encode(str);
    x = ConvertToWordArray(str);
    a = 0x67452301; b = 0xEFCDAB89; c = 0x98BADCFE; d = 0x10325476;

    for (k = 0; k < x.length; k += 16) {
        AA = a; BB = b; CC = c; DD = d;
        a = FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
        d = FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
        c = FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
        b = FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
        a = FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
        d = FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
        c = FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
        b = FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
        a = FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
        d = FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
        c = FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
        b = FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
        a = FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
        d = FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
        c = FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
        b = FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
        a = GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
        d = GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
        c = GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
        b = GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
        a = GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
        d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
        c = GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
        b = GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
        a = GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
        d = GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
        c = GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
        b = GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
        a = GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
        d = GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
        c = GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
        b = GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
        a = HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
        d = HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
        c = HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
        b = HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
        a = HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
        d = HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
        c = HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
        b = HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
        a = HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
        d = HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
        c = HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
        b = HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
        a = HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
        d = HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
        c = HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
        b = HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
        a = II(a, b, c, d, x[k + 0], S41, 0xF4292244);
        d = II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
        c = II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
        b = II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
        a = II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
        d = II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
        c = II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
        b = II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
        a = II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
        d = II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
        c = II(c, d, a, b, x[k + 6], S43, 0xA3014314);
        b = II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
        a = II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
        d = II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
        c = II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
        b = II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
        a = AddUnsigned(a, AA);
        b = AddUnsigned(b, BB);
        c = AddUnsigned(c, CC);
        d = AddUnsigned(d, DD);
    }

    var temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

    return temp.toLowerCase();
}

function reloadContent(id, callback) {
    $.ajax({
        url: window.location.pathname,
        dataType: 'html',
        method: 'GET',
        success: function (resp) {
            if (typeof id === 'string') {
                $(id).html($(resp).find(id).html());
            } else {
                id.forEach(function (item) {
                    $(item).html($(resp).find(item).html());
                });
            }
            if (typeof callback === 'function') {
                callback.call(this, resp);
            }
        },
        complete: function () {

        }
    });
}

function strip_tags(str) {	// Strip HTML and PHP tags from a string
    //
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)

    return str.replace(/<\/?[^>]+>/gi, '');
}

function send_confirm(id) {
    var id = id || null;
    Auth.sendConfirm(id, function (res, msg, fullResponse) {
        show_alert(msg, fullResponse.status);
    });
    return false;
}

function newTab(href) {
    var form = document.createElement("form");
    form.method = "GET";
    form.action = href;
    form.target = "_blank";
    document.body.appendChild(form);
    form.submit();
}

var dateFormat = function () {
    var token = /d{1,4}|m{1,4}|yy(?:yy)?|([HhMsTt])\1?|[LloSZ]|"[^"]*"|'[^']*'/g,
        timezone = /\b(?:[PMCEA][SDP]T|(?:Pacific|Mountain|Central|Eastern|Atlantic) (?:Standard|Daylight|Prevailing) Time|(?:GMT|UTC)(?:[-+]\d{4})?)\b/g,
        timezoneClip = /[^-+\dA-Z]/g,
        pad = function (val, len) {
            val = String(val);
            len = len || 2;
            while (val.length < len) val = "0" + val;
            return val;
        };

    // Regexes and supporting functions are cached through closure
    return function (date, mask, utc) {
        var dF = dateFormat;

        // You can't provide utc if you skip other args (use the "UTC:" mask prefix)
        if (arguments.length == 1 && Object.prototype.toString.call(date) == "[object String]" && !/\d/.test(date)) {
            mask = date;
            date = undefined;
        }

        // Passing date through Date applies Date.parse, if necessary
        date = date ? new Date(date) : new Date;
        if (isNaN(date)) throw SyntaxError("invalid date");

        mask = String(dF.masks[mask] || mask || dF.masks["default"]);

        // Allow setting the utc argument via the mask
        if (mask.slice(0, 4) == "UTC:") {
            mask = mask.slice(4);
            utc = true;
        }

        var _ = utc ? "getUTC" : "get",
            d = date[_ + "Date"](),
            D = date[_ + "Day"](),
            m = date[_ + "Month"](),
            y = date[_ + "FullYear"](),
            H = date[_ + "Hours"](),
            M = date[_ + "Minutes"](),
            s = date[_ + "Seconds"](),
            L = date[_ + "Milliseconds"](),
            o = utc ? 0 : date.getTimezoneOffset(),
            flags = {
                d: d,
                dd: pad(d),
                ddd: dF.i18n.dayNames[D],
                dddd: dF.i18n.dayNames[D + 7],
                m: m + 1,
                mm: pad(m + 1),
                mmm: dF.i18n.monthNames[m],
                mmmm: dF.i18n.monthNames[m + 12],
                yy: String(y).slice(2),
                yyyy: y,
                h: H % 12 || 12,
                hh: pad(H % 12 || 12),
                H: H,
                HH: pad(H),
                M: M,
                MM: pad(M),
                s: s,
                ss: pad(s),
                l: pad(L, 3),
                L: pad(L > 99 ? Math.round(L / 10) : L),
                t: H < 12 ? "a" : "p",
                tt: H < 12 ? "am" : "pm",
                T: H < 12 ? "A" : "P",
                TT: H < 12 ? "AM" : "PM",
                Z: utc ? "UTC" : (String(date).match(timezone) || [""]).pop().replace(timezoneClip, ""),
                o: (o > 0 ? "-" : "+") + pad(Math.floor(Math.abs(o) / 60) * 100 + Math.abs(o) % 60, 4),
                S: ["th", "st", "nd", "rd"][d % 10 > 3 ? 0 : (d % 100 - d % 10 != 10) * d % 10]
            };

        return mask.replace(token, function ($0) {
            return $0 in flags ? flags[$0] : $0.slice(1, $0.length - 1);
        });
    };
}();

// Some common format strings
dateFormat.masks = {
    "default": "ddd mmm dd yyyy HH:MM:ss",
    shortDate: "m/d/yy",
    mediumDate: "mmm d, yyyy",
    longDate: "mmmm d, yyyy",
    fullDate: "dddd, mmmm d, yyyy",
    shortTime: "h:MM TT",
    mediumTime: "h:MM:ss TT",
    longTime: "h:MM:ss TT Z",
    isoDate: "yyyy-mm-dd",
    isoTime: "HH:MM:ss",
    isoDateTime: "yyyy-mm-dd'T'HH:MM:ss",
    isoUtcDateTime: "UTC:yyyy-mm-dd'T'HH:MM:ss'Z'"
};

// Internationalization strings
dateFormat.i18n = {
    dayNames: [
        "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat",
        "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
    ],
    monthNames: [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ]
};

// For convenience...
Date.prototype.format = function (mask, utc) {
    return dateFormat(this, mask, utc);
};
